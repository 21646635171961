<template>
  <v-container class="pa-6">
    <div class="d-flex justify-center align-center">
      <v-card
        class="pa-4"
        min-width="600px"
      >
        <add-device-page />
      </v-card>
    </div>
  </v-container>
</template>

<script>
import AddDevicePage from '@/views/AppIndex/Widget/FilialeManage.vue'

export default {
  name: 'AddFirstDevice',
  components: { AddDevicePage }
}
</script>

<style scoped>

</style>
